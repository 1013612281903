<template>
  <div class="df" style="padding: 20px; flex-direction: column; height: 100%">
    <div class="df aic jcb" style="width: 100%">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>电子鼻</el-breadcrumb-item>
        <el-breadcrumb-item>电子鼻分组</el-breadcrumb-item>
        <el-breadcrumb-item>电子鼻数据</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button
          type="primary"
          @click="downloadAll"
          v-if="multipleSelection.length"
          >批量导出</el-button
        >
      </div>
    </div>
    <div style="flex: 1; height: 0; padding: 20px">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="groupName" label="分组" width="450">
        </el-table-column>
        <el-table-column prop="createdTime" label="上传时间" width="450">
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib blue"
              size="mini"
              @click="openCharts(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              class="disib blue"
              size="mini"
              @click="downloadItem(scope.row)"
              >导出</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- <div style="width: 100%; height: 800px">
        <Echart
          :options="chartsOption"
          style="width: 100%; height: 100%"
          v-if="dialogVisible"
        />
      </div> -->
    </div>
    <!-- <div class="df aic jcc" style="flex-direction: row">
      <el-pagination
        background
        :page-size="query.limit"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="query.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div> -->
    <el-dialog :visible.sync="dialogVisible" width="800px">
      <div style="height: 500px; heigt: 100%; padding: 20px; display: block">
        <Echart
          :options="chartsOption"
          style="width: 100%; height: 100%"
          v-if="dialogVisible"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/util/extra-api";
import Echart from "@/common/echart";
export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
      },
      total: 0,
      tableData: [],
      groupName: "",
      dialogVisible: false,
      checkedItem: null,
      chartsTitle: "",
      multipleSelection: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    downloadAll() {
      this.multipleSelection.forEach((item) => {
        this.downloadItem(item);
      });
    },
    getList() {
      api
        .get("/v1/wx/nearInfrared/getDetailInfo", { groupName: this.groupName })
        .then((res) => {
          if (res.code === 200) {
            this.tableData = res.data;
            this.total = res.total;
          }
        });
    },
    openCharts(row) {
      this.checkedItem = row.data;
      this.chartsTitle = row.createdTime;
      this.dialogVisible = true;
      // console.log(
      //   this.checkedItem.map((v) => {
      //     return {
      //       ...v,
      //       type: "line",
      //       stack: "Total",
      //     };
      //   }),
      //   "测试"
      // );
    },
    downloadItem(row) {
      api.fileget("/v1/wx/nearInfrared/export", { id: row.id }).then((res) => {
        if (res.code === 200) {
          console.log(res.data, 123);
          // window.open(res.data);
        }
      });
    },
    pageChange() {},
  },
  components: {
    Echart,
  },
  computed: {
    chartsOption() {
      if (!this.checkedItem) {
        return {};
      } else {
        let xAxisArr = [];
        console.log(this.checkedItem.length);
        this.checkedItem[0].data.forEach((v, i) => {
          xAxisArr.push(i + 1);
        });
        return {
          color: [
            "#F8D900",
            "#2D8CF0",
            "#19BE6B",
            "#E46CBB",
            "#ED3F14",
            "#fbb1a2",
            "#69b2cf",
            "#139ba2",
            "#D9D2A6",
            "#9366C3",
            "#c4ccd3",
          ],
          title: {
            text: this.chartsTitle,
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            textStyle: {
              color: "#000",
            },
            right: 10,
            top: 20,
            bottom: 20,
            data: [
              "resistance0",
              "resistance1",
              "resistance2",
              "resistance3",
              "resistance4",
              "resistance5",
              "resistance6",
              "resistance7",
              "temperature",
              "humidity",
            ],
          },
          grid: {
            left: "3%",
            right: "20%",
            bottom: "3%",
            containLabel: true,
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            axisLabel: {
              show: true,
              color: "#000",
            },
            nameTextStyle: {
              fontSize:18
            },
            name:'s',
            type: "category",
            boundaryGap: false,
            data: xAxisArr,
          },
          yAxis: {
            // alignTicks: false,
            type: "value",
            axisLabel: {
              color: "#000",
            },
          },
          series: this.checkedItem.map((v) => {
            return {
              ...v,
              type: "line",
            };
          }),
        };
      }
    },
  },
  mounted() {
    this.groupName = this.$route.query.groupName;
    this.getList();
    // [
    //   {
    //     name: "resistance0",
    //     data: [34567, 45678, xxxx],
    //   },
    //   {
    //     name: "resistance1",
    //     data: [xxxx, xxxxx, xxxx],
    //   },
    // ];
  },
};
</script>

<style lang='scss' scoped >
.jcb {
  justify-content: space-between;
}
</style>